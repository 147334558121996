import React from 'react';

import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import { TbNumber1, TbNumber2, TbNumber3, TbNumber4, TbNumber5} from 'react-icons/tb'
import { AiFillStar } from 'react-icons/ai'
import 'react-vertical-timeline-component/style.min.css';
import './Timeline.css';

const Timeline = () => (
  <div className="vertical-timeline__section">
    <VerticalTimeline className="vertical-timeline__start" lineColor="#f4f4f4">
      <VerticalTimelineElement
        className="vertical-timeline-element-1"
        contentStyle={{ background: "#f4f4f4", color: "#111111" }}
        contentArrowStyle={{ borderRight: "7px solid  #f4f4f4" }}
        date=""
        iconStyle={{ background: "#2e937a", color: "#f4f4f4" }}
        icon={<TbNumber1 />}
      >
        <h3 className="vertical-timeline-element-title">
          Personalizzazione e Monitoraggio dei progressi
        </h3>
        <p className="p__opensans">
          Gli esercizi sono personalizzabili dai fisioterapisti per soddisfare
          le esigenze individuali dei pazienti, e il monitoraggio dei progressi
          permette di adattare la riabilitazione per massimizzare l'efficacia
          del trattamento.
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element-2"
        contentStyle={{ background: "#f4f4f4", color: "#111111" }}
        contentArrowStyle={{ borderRight: "7px solid  #f4f4f4" }}
        date=""
        iconStyle={{ background: "#2e937a", color: "#fff" }}
        icon={<TbNumber2 />}
      >
        <h3 className="vertical-timeline-element-title">Varietà di Giochi</h3>
        <p className="p__opensans">
          L'ampia selezione di videogiochi disponibili offre varietà e
          coinvolgimento nel percorso di riabilitazione. Con una vasta scelta di
          giochi adatti a diversi obiettivi di recupero, i pazienti possono
          trovare una gamma diversificata di attività coinvolgenti che rendono
          la riabilitazione più interessante e motivante.
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element-3"
        contentStyle={{ background: "#f4f4f4", color: "#111111" }}
        contentArrowStyle={{ borderRight: "7px solid  #f4f4f4" }}
        date=""
        iconStyle={{ background: "#2e937a", color: "#fff" }}
        icon={<TbNumber3 />}
      >
        <h3 className="vertical-timeline-element-title">Adatto a tutti</h3>
        <p className="p__opensans">
          I giochi possono essere adattati a pazienti di tutte le età e con
          diverse condizioni di riabilitazione, offrendo un'esperienza
          personalizzata e coinvolgente per ciascun individuo.
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element-4"
        contentStyle={{ background: "#f4f4f4", color: "#111111" }}
        contentArrowStyle={{ borderRight: "7px solid  #f4f4f4" }}
        date=""
        iconStyle={{ background: "#2e937a", color: "#fff" }}
        icon={<TbNumber4 />}
      >
        <h3 className="vertical-timeline-element-title">Quando e dove vuoi</h3>
        <p className="p__opensans">
          L'utilizzo di un app per smartphone nella riabilitazione offre
          benefici economici e di risparmio di tempo per le strutture, i
          fisioterapisti e i pazienti stessi, semplificando il processo di
          gestione delle sedute di riabilitazione. Play4physio ti dà la capacita
          di accedere ad una sessione di esercizio comodamente dal tuo
          smartphone in maniera facile e divertente.
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        className="vertical-timeline-element-4"
        contentStyle={{ background: "#f4f4f4", color: "#111111" }}
        contentArrowStyle={{ borderRight: "7px solid  #f4f4f4" }}
        date=""
        iconStyle={{ background: "#2e937a", color: "#fff" }}
        icon={<TbNumber5 />}
      >
        <h3 className="vertical-timeline-element-title">
          Mantieniti in movimento divertendoti
        </h3>
        <p className="p__opensans">
          Grazie alla varietà di videogiochi funzionali e coinvolgenti,
          incoraggiamo i pazienti a svolgere esercizio fisico in modo
          regolare, facilitando il mantenimento di un programma di
          riabilitazione costante nel tempo. Ciò contribuisce a migliorare la
          forza, la flessibilità e la mobilità, favorendo una più rapida e
          completa ripresa.
        </p>
      </VerticalTimelineElement>

      <VerticalTimelineElement
        iconStyle={{ background: "rgb(16, 204, 82)", color: "#fff" }}
        icon={<AiFillStar />}
      />
    </VerticalTimeline>
  </div>
);

export default Timeline;