import React, { useState } from 'react';

import './Newsletter.css';
import SubHeading from '../SubHeading/SubHeading';

const Newsletter = () => {
  const [email, setEmail]= useState("")
  const [message, setMessage]= useState("")

  function handleChange(e){
    setEmail(e.target.value)
  }

  const checkValidation = (e) =>{
    e.preventDefault()
    //regular expression per fare un controllo sull'email inserita
    const regex= /^[a-zA-Z0-9._]+@[a-z]+\.[a-z]{2,6}$/

    if(regex.test(email)){
      //il messaggio qui viene gestito dal server
      sendData(e)
    } else if (email === ""){
      setMessage("Per favore inserire l'email")
    } else if (!regex.test(email)){
      setMessage("l'email inserita non è valida")
    } else{
      setMessage("")
    }

    //effettua un refresh del campo email e del messaggio dopo 5 secondi
    setTimeout(() => {
      setMessage("");
      setEmail("");
    }, 5000);
  }

  const sendData = (e) =>{
    e.preventDefault();

    // manda il file JSON al server tramite chiamata all'API
    fetch("https://play4physio.di.unimi.it/api/saveData", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ email }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("Server response:", data);
        // Gestisci qui la risposta del server
        //setMessage(data.message);
        //mostra il messaggio in una finestra pop-up
        window.alert(data.message)
      })
      .catch((error) => {
        console.error("Error sending data:", error);
      });
  }

  return (
    <div className="app__newsletter" id="newsletter">
      <div className="app__newsletter-heading">
        <SubHeading title="Newsletter" />
        <h1 className="headtext__cormorant">
          Iscriviti alla nostra Newsletter
        </h1>
        <p className="p__opensans">Non perderti gli aggiornamenti!</p>
        <br />
        <p className=" form__message p__opensans">{message}</p>
      </div>
      <form
        onSubmit={checkValidation}
        className="app__newsletter-input flex__center"
      >
        <input
          type="email"
          value={email}
          onChange={handleChange}
          placeholder="Inserisci il tuo indirizzo Email"
        />
        
        <button className="custom__button" type='submit'>Iscriviti</button>
      </form>
    </div>
  );
}; 



export default Newsletter;
