import React from 'react';
import { AboutUs, Footer, Header, IntroVideo, Sponsor } from './container';
import { Navbar , Timeline} from './components';
import './App.css';

const App = () => (
  <div>
    <Navbar />
    <Header />
    <AboutUs />
    {/* decommentare quando i video sono disponibili */}
    {/* <IntroVideo /> */}
    <Timeline />
    <Sponsor />
    <Footer />
  </div>
);

export default App;
