import React from 'react'
import { images } from '../../constants'

import './Sponsor.css'

const Sponsor = () => {
  return (
    <div className="section__padding sponsor__section">
      <h3 className="p__cormorant" style={{ textAlign: "center" }}>
        Partner
      </h3>
      <div className="flex__center ">
        <div className="sponsor__wrapper">
          <a
            href="https://cetbianchibonomi.it"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="sponsor__img"
              src={images.sponsor1}
              alt="sponsor_logo"
            />
          </a>
        </div>

        {/* <div className="sponsor__wrapper">
          <a
            href="https://www.policlinico.mi.it"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="sponsor__img"
              src={images.sponsor3}
              alt="sponsor_logo"
            />
          </a>
        </div> */}

        <div className="sponsor__wrapper">
          <a
            href="https://www.fondazioneluigivilla.online"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="sponsor__img"
              src={images.sponsor2}
              alt="sponsor_logo"
            />
          </a>
        </div>

        <div className="sponsor__wrapper">
          <a
            href="https://www.di.unimi.it"
            target="_blank"
            rel="noreferrer"
          >
            <img
              className="sponsor__img"
              src={images.sponsor6}
              alt="sponsor_logo"
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export default Sponsor
