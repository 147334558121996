import React from "react";
import { Card } from "../../components";
import { images } from "../../constants";
import './Offert.css';

const Offert = () => {
  return (
    <div className="app__bg app__wrapper section__padding">
      <div className="app__wrapper_info">
        <h1 className="headtext__cormorant" style={{ marginBottom: "3rem" }}>
          Caratteristiche di Play4physio
        </h1>

        <div className="app__wrapper-card__container">
          <Card
            imagesSrc={images.gamification_y}
            title="Intrattenimento"
            description="Un'ampia varietà di videogiochi adatti a tutte le età e capaci di rendere più coinvolgenti gli esercizi di routine"
          />

          <Card
            imagesSrc={images.customization}
            title="Personalizzazione"
            description="Gli esercizi verranno personalizzati del fisioterapista per il paziente, in modo da rendere la terapia mirata ed efficace"
          />

          <Card
            imagesSrc={images.analysis}
            title="Monitoraggio"
            description="L'app fornirà un'analisi costante dei progressi in modo da poter effettuare modifiche e correzzioni tempestive al piano di esercizi"
          />
          
          <Card
            imagesSrc={images.training}
            title="Comodità"
            description="Allenamenti quando e dove vuoi nel palmo della tua mano, in modo da fornire un servizio che si adatti alle esigenze del paziente"
          />

        </div>
      </div>

      <div className="app__wrapper_img">
        <img src={images.gamification} alt="workouts" />
      </div>
    </div>
  );
};

export default Offert;
