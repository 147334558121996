import customization from '../assets/app-customization.png';
import analysis from '../assets/app-analysis.png';
import stats from '../assets/app-stats.png';
import fitness_stats from '../assets/fitness-stats.png';
import doctors from '../assets/doctors.png';
import gamification from '../assets/gamification1.png';
import gamification_y from '../assets/gamification-yellow.png';
import trainer from '../assets/personal_trainer.png';
import web_stats from '../assets/site-stats.png';
import stretching from '../assets/stretching.png';
import trophy from '../assets/trophy.png';
import usability from '../assets/usability.png';
import video from '../assets/video-explaining.png';
import workouts from '../assets/workouts.png';
import logo_normal from '../assets/Logo-normal.png';
import logo_orange from '../assets/Logo-orange.png';
import logo_green from '../assets/Logo-green.png';
import gallery1 from '../assets/gallery-01.jpg';
import gallery2 from '../assets/gallery-02.jpg';
import gallery3 from '../assets/gallery-03.jpg';
import gallery4 from '../assets/gallery-04.jpg';
import portrait from '../assets/portrait-photo.jpg';
import sponsor1 from "../assets/logo_CETABB-black.png";
import sponsor2 from "../assets/logo_FLV.png";
import sponsor3 from "../assets/Logo_no_regione_verticale.png";
import sponsor4 from "../assets/unimi.png";
import sponsor5 from "../assets/unimi_logo.png";
import sponsor6 from "../assets/logo_unimi_info.png";
import finanziatore from "../assets/logo_FCM.png";
import gamification_2 from '../assets/gamification2.png';
import playing from '../assets/playing.png';
import training from '../assets/training.png';
import researcher from '../assets/researcher.png';
import Gualtierotti from '../assets/Dr_Gualtierotti.jpg';
import Peyvandi from '../assets/Dr_Peyvandi.jpg';
import Ahmetovic from '../assets/Prof_Ahmetovic.jpg';
import Mascetti from '../assets/Prof_Mascetti.jpg';


export default {
  logo_green,
  logo_orange,
  logo_normal,
  sponsor1,
  sponsor2,
  sponsor3,
  sponsor4,
  sponsor5,
  sponsor6,
  finanziatore,
  analysis,
  fitness_stats,
  doctors, 
  gamification,
  gamification_y,
  stats,
  trainer,
  web_stats,
  stretching,
  trophy,
  usability,
  video,
  workouts,
  customization,
  gallery1,
  gallery2,
  gallery3,
  gallery4,
  portrait,
  gamification_2,
  training,
  playing,
  researcher,
  Gualtierotti,
  Peyvandi,
  Ahmetovic,
  Mascetti
};