import React from 'react';
import './Mission.css';


const Mission = () => {
  return (
    <div className="mission__section">
      <div className="app__wrapper_mission">
        <h1 className="headtext__cormorant">Il Nostro Obbiettivo</h1>
        <p className="p__opensans">
          Miriamo a trasformare il percorso di guarigione, fornendo un'esperienza
          coinvolgente, personalizzata e motivante per aiutare i pazienti a
          superare i propri limiti e raggiungere il massimo potenziale
        </p>
      </div>
    </div>
  );
}

export default Mission