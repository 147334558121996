import React from 'react'
import {images} from '../../constants'
import './ArticlesLinks.css';

const ArticlesLinks = () => {
  return (
    <div className="app__bg app__wrapper section__padding">
      <div className="app__wrapper_info">
        <h1 className="headtext__cormorant" style={{ marginBottom: "3rem" }}>
          Articoli
        </h1>

        <div className="app__wrapper-content">
          <ul className="link__list">
            <li className="p__opensans">
              <a
                href="https://www.google.com/search?client=opera&q=gool&sourceid=opera&ie=UTF-8&oe=UTF-8"
                target="_blank"
                rel="noreferrer"
              >
                Link1
              </a>
            </li>
            <li className="p__opensans">
              <a
                href="https://www.google.com/search?client=opera&q=gool&sourceid=opera&ie=UTF-8&oe=UTF-8"
                target="_blank"
                rel="noreferrer"
              >
                Link2
              </a>
            </li>
            <li className="p__opensans">
              <a
                href="https://www.google.com/search?client=opera&q=gool&sourceid=opera&ie=UTF-8&oe=UTF-8"
                target="_blank"
                rel="noreferrer"
              >
                Link3
              </a>
            </li>
            <li className="p__opensans">
              <a
                href="https://www.google.com/search?client=opera&q=gool&sourceid=opera&ie=UTF-8&oe=UTF-8"
                target="_blank"
                rel="noreferrer"
              >
                Link4
              </a>
            </li>
            <li className="p__opensans">
              <a
                href="https://www.google.com/search?client=opera&q=gool&sourceid=opera&ie=UTF-8&oe=UTF-8"
                target="_blank"
                rel="noreferrer"
              >
                Link5
              </a>
            </li>
            <li className="p__opensans">
              <a
                href="https://www.google.com/search?client=opera&q=gool&sourceid=opera&ie=UTF-8&oe=UTF-8"
                target="_blank"
                rel="noreferrer"
              >
                Link6
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div className="app__wrapper_img">
        <img className="article__img" src={images.usability} alt="analysis" />
      </div>
    </div>
  );
}

export default ArticlesLinks