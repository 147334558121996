import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';
import App from './App';

import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Play4physio from './routes/Play4physio'
import Risorse from './routes/Risorse'
import ChiSiamo from './routes/ChiSiamo';


ReactDOM.render(
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<App/>}/>
        <Route path='/chiSiamo' element={<ChiSiamo />}/>
        <Route path='/play4physio' element={<Play4physio/>}/>
        <Route path='/risorse' element={<Risorse/>}/>
      </Routes>
    </BrowserRouter>,
    document.getElementById('root')
);

