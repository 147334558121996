import React from 'react';

import { SubHeading } from '../../components';
import { images } from '../../constants';

const WhyUs = () => (
  <div
    className="app__bg app__wrapper section__padding"
    style={{ backgroundColor: "#c8e8e0" }}
  >
    <div className="app__wrapper_info">
      <h1 className="headtext__cormorant" style={{ marginBottom: "3rem" }}>
        Perchè Play4physio?
      </h1>

      <div className="app__wrapper-content">
        <SubHeading title="Mantenersi in movimento" />
        <p className="p__opensans">
          Per le persone con emofilia, una malattia emorragica congenita rara,
          la fisioterapia è fondamentale, sin dall'infanzia, al fine di
          prevenire i danni dovuti ai sanguinamenti in sede articolare, che nel
          tempo possono causare disfunzioni croniche e disabilità motorie.
          Tuttavia, è difficile motivare i pazienti, soprattutto i più piccoli,
          a esercitarsi a casa in maniera regolare e corretta.
        </p>
        <br />
        <br />
        <SubHeading title="Problemi di accesso alle sedute" />
        <p className="p__opensans">
          Il COVID, inoltre, ha reso difficile effettuare sedute ambulatoriali di
          fisioterapia in continuità e sicurezza, riducendo ulteriormente la
          possibilità per i pazienti di esercitarsi adeguatamente.
          Infatti, come riportato da studi scientifici del settore e
          associazioni dei pazienti, l'aumento di difficoltà nell'accedere in sicurezza alle sedute, ha comportato che quasi la
          metà dei pazienti ha smesso di svolgere attività fisica in maniera
          adeguata con il conseguente aumento di rischi delle complicanze.
        </p>
      </div>
    </div>

    <div className="app__wrapper_img">
      <img src={images.workouts} alt="workouts" />
    </div>
  </div>
);

export default WhyUs;
