import React, { useState } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { IoClose } from 'react-icons/io5'
import images from '../../constants/images';
import './Navbar.css';
import { Link } from 'react-router-dom';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <Link to="/">
          <img src={images.logo_orange} alt="app logo" />
        </Link>
      </div>

      <ul className="app__navbar-links">
        <li className="p__opensans">
          <Link to="/">Home</Link>
        </li>
        <li className="p__opensans">
          <Link to="/play4physio">Play4physio</Link>
        </li>
        <li className="p__opensans">
          <Link to="/chiSiamo">Chi Siamo</Link>
        </li>
        {/*decommentare quando le risorse video e gli articoli sono disponibili*/}
        {/* <li className="p__opensans">
          <Link to="/risorse">Risorse</Link>
        </li> */}
      </ul>

      <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#e59846"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />

        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom ">
            <IoClose
              fontSize={40}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />
            <ul className="app__navbar-smallscreen_links">
              <li className="p__opensans">
                <Link to="/">Home</Link>
              </li>
              <li className="p__opensans">
                <Link to="/play4physio">Play4physio</Link>
              </li>
              <li className="p__opensans">
                <Link to="/chiSiamo">Chi Siamo</Link>
              </li>
              {/*decommentare quando le risorse video e gli articoli sono disponibili*/}
              {/* <li className="p__opensans">
                <Link to="/risorse">Risorse</Link>
              </li> */}
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
}

export default Navbar;
