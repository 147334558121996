import React from 'react'
import { SubHeading } from '../../components';
import { images } from '../../constants';

const LabDescription = () => {
  return (
    <div className="app__header app__wrapper2 section__padding">
      <div className="app__wrapper_info">
        <SubHeading title="Dipartimento di Informatica, Università degli studi di Milano" />
        <h1 className="headtext__descriptions">Everyware Lab</h1>
        <p className="p__opensans" style={{ margin: "2rem 0" }}>
          Il Dipartimento di Informatica, capofila del progetto, è formato da
          oltre 200 membri tra docenti, personale tecnico/amministrativo,
          dottorandi e assegnisti. Presenta un'ampia offerta formativa, con
          quattro Corsi di Laurea Triennale, due di Laurea Magistrale, ed una
          Scuola di Dottorato in Informatica. Svolge attività di ricerca di base
          e applicata, sia nel campo dell'informatica che nelle aree affini e
          interdisciplinari. Tali attività sono attuate prevalentemente
          nell'ambito di progetti di ricerca nazionali e internazionali presso i
          laboratori di ricerca del Dipartimento. Il laboratorio EveryWare,
          proponente del progetto, è attivo da 10 anni sulle tematiche chiave
          coinvolte nel progetto: sistemi mobili e pervasivi, privacy,
          intelligenza artificiale e tecnologie assistive.
        </p>
      </div>

      <div className="app__wrapper_img">
        <img src={images.web_stats} alt="header img" />
      </div>
    </div>
  );
}

export default LabDescription
