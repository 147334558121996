import React from 'react';
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from 'react-icons/fi';

import './Footer.css';
import { FooterOverlay, Newsletter } from '../../components';
import { images } from '../../constants';

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <FooterOverlay />
    <Newsletter />

    <div className="app__footer-links">
      <div className="app__footer-links_contact">
        <p className="p__opensans">Con il contributo di:</p>
        <br />
        <a
          target="_blank"
          rel="noreferrer"
          href="https://www.fondazionecomunitamilano.org"
        >
          <img id="logo_fcm" src={images.finanziatore} alt="fcm_logo" />
        </a>
      </div>

      <div className="app__footer-links_logo">
        <p className="p__opensans">Seguici sui nostri social</p>
        <br />

        <div className="app__footer-links_icons">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.facebook.com/profile.php?id=61555416503395"
          >
            <FiFacebook />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.instagram.com/play4physio_official/"
          >
            <FiInstagram />
          </a>

          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.linkedin.com/company/play4physio/"
          >
            <FiLinkedin />
          </a>
        </div>
      </div>

      {/* <div className="app__footer-links_work">
        <h1 className="app__footer-headtext">Orari</h1>
        <p className="p__opensans">Lunedi-Venerdi</p>
        <p className="p__opensans">09:00 am - 12:00 am</p>
        <p className="p__opensans">Sabato-Domenica</p>
        <p className="p__opensans">/</p>
      </div> */}
    </div>

    <div className="footer__copyright">
      <a
        className="p__opensans"
        target="_blank"
        rel="noreferrer"
        href="https://www.freepik.com/author/stories"
      >
        Images by storyset
      </a>
    </div>
  </div>
);

export default Footer;
