import React from 'react'
import './StaffCard.css'

const StaffCard = ({image, name, content}) => {
  return (
    <div className="staff__card-container">
      <div className="staff__card-image_container">
        <img src={image} alt="staff" className="staff__card-image" />
      </div>

      <div className="staff__card-content">
        <h4 className="p__cormorant">{name}</h4>
        <p className="p__opensans">{content} </p>
      </div>
    </div>
  );
}

export default StaffCard