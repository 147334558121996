import React from 'react'
import SubHeading from '../SubHeading/SubHeading';
import './Card.css'

const Card = ({imagesSrc, title, description}) => {
  return (
    <div className="card">
      <img className="card-image" src={imagesSrc} alt="CardImg" />
      <div className="card-content">
        <SubHeading title={title} />
        <p className="card-description p__opensans">{description}</p>
      </div>
    </div>
  );
}

export default Card