import React from 'react'
import { SubHeading } from "../../components";
import { images } from "../../constants";

const PoliclinicoDescription = () => {
 return (
   <div className="app__header app__wrapper2 section__padding">
     <div className="app__wrapper_info">
       <SubHeading title=" Fondazione IRCCS Ca’ Granda Ospedale Maggiore Policlinico di Milano" />
       <h1 className="headtext__descriptions">
         Centro Emofilia e Trombosi Angelo Bianchi Bonomi
       </h1>
       <p className="p__opensans" style={{ margin: "2rem 0" }}>
         Il Centro Emofilia e Trombosi Angelo Bianchi Bonomi, riferimento
         regionale per i pazienti affetti da malattie emorragiche congenite, è
         parte integrante dell'Unità Operativa Complessa di Medicina Generale -
         Emostasi e Trombosi della Fondazione IRCCS Ca' Granda Ospedale Maggiore
         Policlinico di Milano. È composto da circa 60 medici, biologi, tecnici
         di laboratorio, statistici, coordinatori di studi clinici, e fornisce
         un ampio ventaglio di prestazioni diagnostiche e trattamenti,
         assistendo ogni anno più di 2000 pazienti con emofilia di tutte le età.
         Si distingue per l'attività di ricerca applicata alla clinica,
         orientata alla sperimentazione di terapie innovative e all'applicazione
         di tecniche fisioterapiche all'avanguardia, con la valutazione del loro
         impatto sulla qualità della vita dei pazienti.
       </p>
     </div>

     <div className="app__wrapper_img">
       <img src={images.doctors} alt="header img" />
     </div>
   </div>
 );
}

export default PoliclinicoDescription
