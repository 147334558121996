import React from 'react'
import { Navbar } from '../components';
import { WhyUs, Footer, Offert, Gallery, Sponsor } from '../container';

const Play4physio = () => {
  return (
    <>
      <Navbar />
      <WhyUs />
      <Offert />
      {/* <Gallery/> */}
      <Sponsor />
      <Footer />
    </>
  );
}

export default Play4physio