import React from 'react';

import {SubHeading} from '../../components';
import {images} from '../../constants';
import './Header.css';

const Header = () => {

  //funzione per il custom button, rimanda direttamente alla newsletter in fondo alla pagina
  const handleClickScroll =()=> {
    const tag= document.getElementById('newsletter');

    if (tag){
      tag.scrollIntoView({behavior: 'smooth'});
    }
  };

  return (
    <div className="app__header app__wrapper section__padding" id="home">
      <div className="app__wrapper_info">
        <SubHeading title="Quando vuoi, Dove vuoi" />
        <h1 className="app__header-h1">Il videogioco in movimento</h1>
        <p className="p__opensans" style={{ margin: "2rem 0" }}>
          Esplora un nuovo modo di fare riabilitazione: scopri
          il mondo coinvolgente dei videogiochi, personalizza gli esercizi,
          monitora i progressi e rendi la tua riabilitazione più efficace grazie
          a Play4physio
        </p>
        <button
          type="button"
          className="custom__button"
          onClick={handleClickScroll}
        >
          Iscriviti
        </button>
      </div>


      <div className="app__wrapper_img">
	<div className="app__video_responsive">
		<iframe width="640" height="360" src="https://www.youtube.com/embed/_E125sQ-mvE?si=e_QiX6uRWwddefHy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
	</div>
      </div>
    </div>
  );
  
  };

export default Header;
