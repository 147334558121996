import React from 'react';

import {images} from '../../constants'
import { StaffCard } from '../../components';
import './Staff.css';

const Staff = () => (
  <div className="app__bg section__padding staff__section">
    <h1 className="headtext__cormorant staff__title">
      Docenti e Collaboratori
    </h1>

    <StaffCard
      image={images.Ahmetovic}
      name={"Prof. Dragan Ahmetovic"}
      content={
        "Professore Associato presso il Dipartimento di Informatica. Ha lavorato sulle tematiche in questione presso università nazionali e internazionali di rilievo (Carnegie Mellon, UC Santa Cruz, Università di Milano e Torino), con più di 50 pubblicazioni scientifiche all'attivo. La sua ricerca si concentra sulle tecnologie assistive progettate per potenziare le capacità cognitive degli utenti e superare le barriere che limitano il loro accesso alle informazioni e al mondo fisico. Si occupa di recupero di conoscenze, interfacce utente intelligenti e interazione uomo-computer, mediate da dispositivi mobili e pervasivi. Dal 2024 è Vice President of Accessibility dello Special Interest Group for Computer-Human Interaction (SIGCHI) della Association for Computing Machinery (ACM)."
      }
    />
    <StaffCard
      image={images.Mascetti}
      name={"Prof. Sergio Mascetti"}
      content={
        "Professore Associato presso il Dipartimento di Informatica e docente dei corsi di mobile computing e mobile app development, con oltre 10 anni di esperienza nello sviluppo e commercializzazione di applicazioni assistive su dispositivi mobili. I suoi interessi di ricerca si concentrano nell'ambito dei sistemi mobili e pervasivi, con particolare attenzione ai campi applicativi delle tecnologie assistive e della privacy. Nel 2011 ha cofondato la spin-off EveryWare Technology e ha ricoperto il ruolo di Chief Technology Officer fino al 2018. Nel campo delle tecnologie assistive, il suo contributo di ricerca consiste nel progettare, sviluppare e valutare nuove soluzioni per supportare l'educazione e la mobilità delle persone con disabilità, in particolare persone con problemi visivi e motori."
      }
    />
    <StaffCard
      image={images.Gualtierotti}
      name={"Prof. Roberta Gualtierotti"}
      content={
        "Medico Reumatologo presso il Centro Emofilia e Trombosi ``Angelo Bianchi Bonomi'' del Policlinico di Milano e Professore Associato di Medicina Interna presso l'Università degli Studi di Milano. Ha conseguito il Dottorato di Ricerca in Patologia Sperimentale e Neuropatologia presso l'Università degli Studi di Milano ed è autrice di oltre 60 articoli su riviste internazionali. Collabora con gli altri specialisti del centro offrendo valutazioni multidisciplinari personalizzate. Ha conseguito nel 2020 due importanti riconoscimenti nel campo dell'emofilia: il Bayer Hemophilia Award Program for Clinical Research e il Martin Villar Award, per gli studi traslazionali e l'uso dell'ecografia muscoloscheletrica nelle coagulopatie ereditarie. È membro del gruppo muscoloscheletrico dell'AICE (Associazione Italiana Centri Emofilia)."
      }
    />
    <StaffCard
      image={images.Peyvandi}
      name={"Prof. Flora Peyvandi"}
      content={
        "Vice-presidente della Fondazione Luigi Villa e presidente eletto della Società Internazionale di Emostasi e Trombosi (ISTH), presidente dell'Associazione Europea per Emofilia e Disturbi correlati (EAHAD), e membro del Comitato Tecnico Sanitario del Ministero della Salute Italiano. La Prof. Peyvandi conduce ricerca all'avanguardia sui disturbi della coagulazione, e vanta più di 540 pubblicazioni scientifiche nell'ambito. Nel 2014 ha ricevuto il premio “Grande Ippocrate” come ricercatore medico italiano dell'anno per capacità comunicativa dei propri risultati scientifici."
      }
    />
  </div>
);

export default Staff;
