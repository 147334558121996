import React from 'react'
import { Navbar } from '../components'
import {  Footer, LVDescription, LabDescription, Mission, PoliclinicoDescription, Sponsor, Staff } from '../container'

const ChiSiamo = () => {
  return (
    <>
       <Navbar />
       <Mission/>
       <LabDescription />
       <PoliclinicoDescription/>
       <LVDescription/>
       <Staff />
       <Sponsor/>
       <Footer />
    </>
  )
}

export default ChiSiamo