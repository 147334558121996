import React from 'react';

import './AboutUs.css';

const AboutUs = () => (
  <div
    className="app__aboutus app__bg flex__center section__padding"
    id="about"
  >
    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">Il Problema</h1>
        <p className="p__opensans">
          Nel percorso di riabilitazione di una persona emofilica, si affrontano
          diversi problemi che richiedono attenzione e soluzioni mirate. Data la
          vasta gamma di età dei pazienti, l'approccio deve essere diversificato
          per soddisfare le esigenze specifiche di ciascun individuo. Uno dei
          principali ostacoli è trovare giochi funzionali adatti a tutte le
          fasce d'età, soprattutto per i più giovani, in modo da mantenere alto e costante il livello di attività fisica svolta dal paziente. Creare inoltre uno stimolo costante che coinvolga i
          pazienti che necessitano di terapie di prevenzione invece che di
          recupero può risultare difficile, complicando ulteriormente il
          processo di riabilitazione.
        </p>

        <p className="p__opensans">
          {" "}
          Affrontare questi problemi richiede un approccio focalizzato
          sull'implementazione di soluzioni personalizzate, coinvolgenti e
          accessibili per ottimizzare il percorso di riabilitazione.
        </p>
      </div>
    </div>
  </div>
);

export default AboutUs;
