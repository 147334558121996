import React from 'react'
import { Navbar } from '../components';
import { ArticlesLinks, Footer, VideoResources, Sponsor } from '../container';

const Risorse = () => {
  return (
    <>
      <Navbar />
      <VideoResources />
      <ArticlesLinks />
      <Sponsor />
      <Footer />
    </>
  );
}

export default Risorse