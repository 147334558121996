import React from 'react'
import { images } from "../../constants";

const LVDescription = () => {
  return (
    <div className="app__header app__wrapper2 section__padding">
      <div className="app__wrapper_info">
        <h1 className="headtext__descriptions">Fondazione Luigi Villa</h1>
        <p className="p__opensans" style={{ margin: "2rem 0" }}>
          La Fondazione Luigi Villa è un ente privato senza scopo di lucro, con
          fine diagnostico e di ricerca scientifica nell'ambito delle sindromi
          emorragiche e della trombosi. Collabora con diversi istituti, in
          particolare con l'Università degli Studi di Milano e con la Fondazione
          IRCCS Ca' Granda Ospedale Maggiore Policlinico, nello sviluppo di
          metodologie all'avanguardia. I risultati delle ricerche sono
          pubblicati su riviste scientifiche riconosciute a livello Nazionale ed
          Internazionale. La fondazione è composta da medici ematologi, biologi,
          biotecnologi e tecnici, sia ospedalieri che universitari, che
          applicano la loro esperienza nei diversi campi di diagnostica e
          ricerca di cui si compone l'attività della Fondazione.
        </p>
      </div>

      <div className="app__wrapper_img">
        <img src={images.researcher} alt="header img" />
      </div>
    </div>
  );
}

export default LVDescription
