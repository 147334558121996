import React from 'react'
import { video1, video2 } from '../../constants';
import './VideoResources.css';

const VideoResources = () => {
  return (
    <div className='app__video-resources section__padding'>

        <h1 className='headtext__cormorant app__video-section_title'>Risorse Video</h1>

        <h3 className='video__title p__cormorant '>Video Title</h3>
        <div className='app__video-wrapper flex__center'>
            <video 
            src={video1}
            type='video/mp4'
            loop
            controls={true}
            volume={null} 
            />
        </div>

        <h3 className='video__title p__cormorant '>Video Title</h3>
        
        <div className='app__video-wrapper flex__center'>
            <video 
            src={video2}
            type='video/mp4'
            loop
            controls={true}
            volume={null} 
            />
        </div>

    </div>
  )
}

export default VideoResources